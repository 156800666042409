const config = {
  apiGateway: {
    REGION: 'us-west-2',
    PHY_LOCATION_APIGATEWAY_URL: 'https://pwy0defi2d.execute-api.us-west-2.amazonaws.com/prod',
    INTERNAL_PHY_LOCATION_APIGATEWAY_URL: 'https://lu2ss6ll9f.execute-api.us-west-2.amazonaws.com/prod',
    COMPANY_APIGATEWAY_URL: 'https://k6tv99g81f.execute-api.us-west-2.amazonaws.com/prod',
  },
  reactApp: {
    VERSION: 'taxonomy-physical-location-1.11.2',
    HOSTNAME: 'https://locations.dev.muttley.forwoodsafety.com'
  },
  configurationService: {
    URL: 'https://config.dev.muttley.forwoodsafety.com',
    WEBSOCKET: 'wss://3se1lnc7f3.execute-api.us-west-2.amazonaws.com/dev'
  },
};

export default config;